import { graphql, Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
} from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import { colors } from '../styles/colors';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      background: ${colors.darkmode};
    }
  }
`;

interface CategoriesProps {
  data: {
    allCategoryYaml: {
      distinct: string[];
    };
    allMarkdownRemark: {
      edges: string[];
      node: any;
    };
  };
}

const getRecentPosts = (categoryName, postsGroup) => {
  const findPost = postsGroup.group.find(group => {
    return group.edges[0].node.frontmatter.category === categoryName;
  });
  return findPost.edges;
};

const categorySection = ({ category, description = '' }, { data }) => (
  <CategorySection>
    <h2>{category}</h2>
    <p>
      <i>{category}</i> {description}. Here are some of the most recent posts:
    </p>
    <ul>
      {getRecentPosts(category.toLowerCase(), data.allMarkdownRemark).map(post => {
        return (
          <li key={post.node.id}>
            <div>{post.node.frontmatter.date} - </div>
            <Link to={post.node.fields.slug}>{post.node.frontmatter.title}</Link>
          </li>
        );
      })}
    </ul>
    <p>
      View all posts from <Link to={`/categories/${category.toLowerCase()}`}>{category}</Link>
    </p>
  </CategorySection>
);

const Categories: React.FC<CategoriesProps> = props => {
  return (
    <IndexLayout>
      <Helmet>
        <title>Categories</title>
      </Helmet>
      <Wrapper css={PageTemplate}>
        <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
          <div css={[SiteNavMain]}>
            <div css={inner}>
              <SiteNav isHome={false} />
            </div>
          </div>
        </header>
        <main id="site-main" className="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            <article className="post page" css={[PostFull, NoImage]}>
              <PostFullHeader>
                <PostFullTitle>Categories</PostFullTitle>
              </PostFullHeader>
              <PostFullContent>
                {[
                  { category: 'Penser', description: 'are travelling, visits, cooking recipes' },
                  {
                    category: 'Experimenter',
                    description: 'are musings on life',
                  },
                  {
                    category: 'Regarder',
                    description: 'will be posts with photos and little to no text',
                  },
                ].map(c => categorySection(c, props))}
              </PostFullContent>
            </article>
          </div>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

const CategorySection = styled.section`
  p {
    text-transform: none;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    color: ${colors.blue};
    transition: all 0.2s ease-in-out;
    div {
      display: inline;
      color: #90a2aa;
      letter-spacing: 0.2px;
      text-transform: uppercase;
    }
    a {
      color: ${colors.blue};
      transition: all 0.2s ease-in-out;
    }
    a:hover {
      color: ${colors.green};
      text-decoration: underline;
    }
  }
`;

export default Categories;

export const mostRecentExperimenter = graphql`
  query {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      group(field: frontmatter___category, limit: 3) {
        edges {
          node {
            id
            frontmatter {
              category
              date(formatString: "D MMM YYYY")
              title
            }
            fields {
              slug
            }
          }
        }
      }
    }
  }
`;
